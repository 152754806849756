@charset "utf-8";

/*
 * base_style.css
 *  + Font Size
 *  + Font Bold Oblique
 *  + Background None
 *  + Border None
 *  + Align
 *  + Float
 *  + Width
 *  + Padding
 *  + Margin
*/


/* ----------------------------------------------------------

 Base Style

---------------------------------------------------------- */

/*  Font Size
---------------------------------------------------------- */
/* px */
.fz_8px{font-size:8px !important;}
.fz_9px{font-size:9px !important;}
.fz_10px{font-size:10px !important;}
.fz_11px{font-size:11px !important;}
.fz_12px{font-size:12px !important;}
.fz_13px{font-size:13px !important;}
.fz_14px{font-size:14px !important;}
.fz_15px{font-size:15px !important;}
.fz_16px{font-size:16px !important;}
.fz_17px{font-size:17px !important;}
.fz_18px{font-size:18px !important;}
.fz_19px{font-size:19px !important;}
.fz_20px{font-size:20px !important;}
.fz_23px{font-size:23px !important;}

/* % */
.fz_70pa{font-size:70% !important;}
.fz_72pa{font-size:72% !important;}
.fz_74pa{font-size:74% !important;}
.fz_76pa{font-size:76% !important;}
.fz_78pa{font-size:78% !important;}
.fz_80pa{font-size:80% !important;}
.fz_82pa{font-size:82% !important;}
.fz_84pa{font-size:84% !important;}
.fz_86pa{font-size:86% !important;}
.fz_88pa{font-size:88% !important;}
.fz_90pa{font-size:90% !important;}
.fz_92pa{font-size:92% !important;}
.fz_94pa{font-size:94% !important;}
.fz_96pa{font-size:96% !important;}
.fz_98pa{font-size:98% !important;}
.fz_100pa{font-size:100% !important;}
.fz_102pa{font-size:102% !important;}
.fz_104pa{font-size:104% !important;}
.fz_106pa{font-size:106% !important;}
.fz_108pa{font-size:108% !important;}
.fz_110pa{font-size:110% !important;}
.fz_112pa{font-size:112% !important;}
.fz_114pa{font-size:114% !important;}
.fz_116pa{font-size:116% !important;}
.fz_118pa{font-size:118% !important;}
.fz_120pa{font-size:120% !important;}
.fz_122pa{font-size:122% !important;}
.fz_124pa{font-size:124% !important;}
.fz_126pa{font-size:126% !important;}
.fz_128pa{font-size:128% !important;}
.fz_130pa{font-size:130% !important;}
.fz_132pa{font-size:132% !important;}
.fz_134pa{font-size:134% !important;}
.fz_136pa{font-size:136% !important;}
.fz_138pa{font-size:138% !important;}
.fz_140pa{font-size:140% !important;}
.fz_142pa{font-size:142% !important;}
.fz_144pa{font-size:144% !important;}
.fz_146pa{font-size:146% !important;}
.fz_148pa{font-size:148% !important;}
.fz_150pa{font-size:150% !important;}

/*  Font Bold Oblique
---------------------------------------------------------- */
.fw_bold   {font-weight:bold   !important;}
.fw_oblique{font-style:oblique !important;}

/*  Font Color
---------------------------------------------------------- */
.fc_red{color:#C00 !important;}
.fc_brown{color:#986300 !important;}
.fc_pink{color:#f184ae !important;}
#about .fc_pink{color:#e55a8f !important;}

/*  Background None
---------------------------------------------------------- */
.no_bg{background: none !important;}

/*  Border None
---------------------------------------------------------- */
.no_border{border: none !important;}

/*  Align
---------------------------------------------------------- */
.ta_center{text-align: center    !important;}
.ta_left  {text-align: left      !important;}
.ta_right {text-align: right     !important;}
.va_top   {vertical-align:top    !important; display:inline;}
.va_middle{vertical-align:middle !important; display:inline;}
.va_bottom{vertical-align:bottom !important; display:inline;}

/*  Float
---------------------------------------------------------- */
.flo_l{float: left  !important;}
.flo_r{float: right !important;}
.flo_n{float: none  !important;}

/*  Width
---------------------------------------------------------- */

/*  Padding
---------------------------------------------------------- */
.pd0 {padding:0 !important;}

/* pl */
.pl5  {padding-left:5px !important;}
.pl10 {padding-left:10px !important;}
.pl15 {padding-left:15px !important;}
.pl20 {padding-left:20px !important;}
.pl25 {padding-left:25px !important;}
.pl30 {padding-left:30px !important;}
.pl35 {padding-left:35px !important;}
.pl40 {padding-left:40px !important;}
.pl45 {padding-left:45px !important;}
.pl50 {padding-left:50px !important;}
.pl60 {padding-left:60px !important;}
.pl70 {padding-left:70px !important;}
.pl80 {padding-left:80px !important;}
.pl90 {padding-left:90px !important;}
.pl100 {padding-left:100px !important;}
/* pr */
.pr5  {padding-right:5px !important;}
.pr10 {padding-right:10px !important;}
.pr15 {padding-right:15px !important;}
.pr20 {padding-right:20px !important;}
.pr25 {padding-right:25px !important;}
.pr30 {padding-right:30px !important;}
.pr35 {padding-right:35px !important;}
.pr40 {padding-right:40px !important;}
.pr45 {padding-right:45px !important;}
.pr50 {padding-right:50px !important;}
.pr60 {padding-right:60px !important;}
.pr70 {padding-right:70px !important;}
.pr80 {padding-right:80px !important;}
.pr90 {padding-right:90px !important;}
.pr100 {padding-right:100px !important;}
/* pt */
.pt0  {padding-top:0px !important;}
.pt5  {padding-top:5px !important;}
.pt10 {padding-top:10px !important;}
.pt15 {padding-top:15px !important;}
.pt20 {padding-top:20px !important;}
.pt25 {padding-top:25px !important;}
.pt30 {padding-top:30px !important;}
.pt35 {padding-top:35px !important;}
.pt40 {padding-top:40px !important;}
.pt45 {padding-top:45px !important;}
.pt50 {padding-top:50px !important;}
.pt55 {padding-top:55px !important;}
.pt60 {padding-top:60px !important;}
.pt65 {padding-top:65px !important;}
.pt70 {padding-top:70px !important;}
.pt75 {padding-top:75px !important;}
.pt80 {padding-top:80px !important;}
.pt85 {padding-top:85px !important;}
.pt90 {padding-top:90px !important;}
.pt95 {padding-top:95px !important;}
.pt100 {padding-top:100px !important;}
/* pb */
.pb0  {padding-bottom:0px !important;}
.pb5  {padding-bottom:5px !important;}
.pb10 {padding-bottom:10px !important;}
.pb15 {padding-bottom:15px !important;}
.pb20 {padding-bottom:20px !important;}
.pb25 {padding-bottom:25px !important;}
.pb30 {padding-bottom:30px !important;}
.pb35 {padding-bottom:35px !important;}
.pb40 {padding-bottom:40px !important;}
.pb45 {padding-bottom:45px !important;}
.pb50 {padding-bottom:50px !important;}
.pb55 {padding-bottom:55px !important;}
.pb60 {padding-bottom:60px !important;}
.pb65 {padding-bottom:65px !important;}
.pb70 {padding-bottom:70px !important;}
.pb75 {padding-bottom:75px !important;}
.pb80 {padding-bottom:80px !important;}
.pb85 {padding-bottom:85px !important;}
.pb90 {padding-bottom:90px !important;}
.pb95 {padding-bottom:95px !important;}
.pb100 {padding-bottom:100px !important;}


/*  Margin
---------------------------------------------------------- */
.ma0 {margin:0 !important;}
.m_auto{ margin:0 auto !important;}

/* ml */
.ml0 {margin-left:0px !important;}
.ml1 {margin-left:1px !important;}
.ml5 {margin-left:5px !important;}
.ml10 {margin-left:10px !important;}
.ml15 {margin-left:15px !important;}
.ml20 {margin-left:20px !important;}
.ml25 {margin-left:25px !important;}
.ml30 {margin-left:30px !important;}
.ml35 {margin-left:35px !important;}
.ml40 {margin-left:40px !important;}
.ml45 {margin-left:45px !important;}
.ml50 {margin-left:50px !important;}
.ml60 {margin-left:60px !important;}
.ml70 {margin-left:70px !important;}
.ml80 {margin-left:80px !important;}
.ml90 {margin-left:90px !important;}
.ml100 {margin-left:100px !important;}
.ml110 {margin-left:110px !important;}
/* mr */
.mr0 {margin-right:0px !important;}
.mr5 {margin-right:5px !important;}
.mr10 {margin-right:10px !important;}
.mr12 {margin-right:12px !important;}
.mr15 {margin-right:15px !important;}
.mr18 {margin-right:18px !important;}
.mr20 {margin-right:20px !important;}
.mr25 {margin-right:25px !important;}
.mr30 {margin-right:30px !important;}
.mr35 {margin-right:35px !important;}
.mr40 {margin-right:40px !important;}
.mr45 {margin-right:45px !important;}
.mr50 {margin-right:50px !important;}
.mr60 {margin-right:60px !important;}
/* mt */
.mt0 {margin-top:0px !important;}
.mt1 {margin-top:1px !important;}
.mt5 {margin-top:5px !important;}
.mt10 {margin-top:10px !important;}
.mt15 {margin-top:15px !important;}
.mt19 {margin-top:19px !important;}
.mt20 {margin-top:20px !important;}
.mt25 {margin-top:25px !important;}
.mt30 {margin-top:30px !important;}
.mt35 {margin-top:35px !important;}
.mt40 {margin-top:40px !important;}
.mt45 {margin-top:45px !important;}
.mt50 {margin-top:50px !important;}
.mt60 {margin-top:60px !important;}
.mt70 {margin-top:70px !important;}
.mt80 {margin-top:80px !important;}
.mt90 {margin-top:90px !important;}
.mt100 {margin-top:100px !important;}
/* mb */
.mb0 {margin-bottom:0px !important;}
.mb3 {margin-bottom:3px !important;}
.mb5 {margin-bottom:5px !important;}
.mb8 {margin-bottom:8px !important;}
.mb10 {margin-bottom:10px !important;}
.mb15 {margin-bottom:15px !important;}
.mb20 {margin-bottom:20px !important;}
.mb25 {margin-bottom:25px !important;}
.mb30 {margin-bottom:30px !important;}
.mb35 {margin-bottom:35px !important;}
.mb40 {margin-bottom:40px !important;}
.mb45 {margin-bottom:45px !important;}
.mb50 {margin-bottom:50px !important;}
.mb60 {margin-bottom:60px !important;}
.mb70 {margin-bottom:70px !important;}
.mb80 {margin-bottom:80px !important;}
.mb90 {margin-bottom:90px !important;}
.mb100 {margin-bottom:100px !important;}
.mb110 {margin-bottom:110px !important;}
.mb120 {margin-bottom:120px !important;}
.mb130 {margin-bottom:130px !important;}
.mb140 {margin-bottom:140px !important;}
.mb150 {margin-bottom:150px !important;}
.mb160 {margin-bottom:160px !important;}
.mb170 {margin-bottom:170px !important;}
.mb180 {margin-bottom:180px !important;}
.mb190 {margin-bottom:190px !important;}
.mb200 {margin-bottom:200px !important;}
/* mg */
.mg5 {margin:5px !important;}
.mg10 {margin:10px !important;}
.mg15 {margin:15px !important;}
.mg20 {margin:20px !important;}
.mg25 {margin:25px !important;}
.mg30 {margin:30px !important;}
.mg35 {margin:35px !important;}
.mg40 {margin:40px !important;}
.mg45 {margin:45px !important;}
.mg50 {margin:50px !important;}
/* pd */
.pd5 {padding:5px !important;}
.pd10 {padding:10px !important;}
.pd15 {padding:15px !important;}
.pd20 {padding:20px !important;}
.pd25 {padding:25px !important;}
.pd30 {padding:30px !important;}
.pd35 {padding:35px !important;}
.pd40 {padding:40px !important;}
.pd45 {padding:45px !important;}
.pd50 {padding:50px !important;}



@media screen and (max-width:740px){
	.w350{ width:95% !important;}
	.mb80 {margin-bottom:30px !important;}
	
}